@use '@/styles/utils/mixins.scss' as *;

.campaignSliderWrapper {
  display: flex;
  .fullWidthContainer {
    max-width: 100%;
    width: 100%;
    padding: 0;

    .helpCardContent {
      .description {
        max-width: 100% !important;
      }
    }
  }
  .smallContainer {
    width: 100%;
    margin: 0 auto;
    padding: 0 3.75rem;
    @include mob() {
      padding: 0 0rem;
    }
  }
  .helpCardContainer {
    background-color: $primary;

    border-radius: 1.25rem;

    .helpCardContent {
      display: flex;
      gap: 5rem;
      color: $white;
      padding: 3rem 3.75rem;

      @include mob() {
        flex-direction: column;
        padding: 2.5rem 1.875rem;
      }

      .leftContent {
        color: $white;
        display: flex;
        flex-direction: column;
        gap: 3.75rem;
        width: 50%;
        flex: 1 1 auto;

        .preText {
          color: $white;
        }
        @include tab() {
          width: 100%;
        }
        @include mob() {
          gap: 2.5rem;
          grid-column: span 1;
        }
        .heading {
          & > * {
            display: flex;
            flex-direction: column;
          }
        }

        .description {
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.525rem;
          width: 64%;

          @include tab() {
            width: 100%;
          }
        }
        :global {
          span {
            color: $white;
          }
        }
      }

      .rightContent {
        position: relative;
        text-align: right;
        width: 50%;
        flex: 1 1 auto;
        height: 100%;

        @include tab() {
          text-align: center;
          width: 100%;
          margin-top: auto;
        }

        .cardImage {
          border-radius: 1.25rem;
          max-width: 100%;
          height: auto;
          width: auto;
          max-height: 25rem;
        }
      }
    }

    .ctaCardBtn {
      width: fit-content;
      margin-top: auto;
      @include mob() {
        width: 100%;
      }
    }
  }

  .swipeWrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 3.75rem;
    margin-top: 1.5rem;
    @include mob() {
      padding: 0 1.875rem;
    }
  }
  .swiperIconBtn {
    display: flex;
    align-items: center;
    gap: 1.25rem;
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: $primary;
    font-weight: 500;
    transition: all 0.2s ease-in-out;

    img {
      transition: all 0.2s ease-in-out;
    }

    &:hover,
    &:focus,
    &:active {
      color: $primaryAlt;
      img {
        opacity: 0.5;
      }
    }
  }
}

.contactBlock {
  padding: 0 0 20px 0;
  h2 {
    font-size: 24px;
    font-weight: 500;
  }
  p {
    font-size: 16px;
    font-weight: 300;
    padding-top: 10px;
  }
}

.divider {
  height: 3px;
  background-color: #dfbb5d;
  width: 64%;
  max-width: 414px;
  margin-bottom: 20px;
}
